import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { redirectTo } from '@reach/router';
import { graphql } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

import { Query } from 'src/graphql-types';
import { DefaultLayoutTemplate } from 'src/pages/layout';
import { defaultRichTextOptions } from 'src/templates/utils/richTextOptions/defaultOptions';
import { PageProps } from 'src/utils/page-props';
import { SectionContainer, SectionContainerTitle } from 'src/components/sectionContainer';
import { curatedTitle } from 'src/utils/curated-title';

export const pageQuery = graphql`
	query($id: String!, $locale: String!) {
		contentfulPage(contentful_id: { eq: $id }, node_locale: { eq: $locale }) {
			title
			body {
				...RichTextFragment
			}
		}
	}
`;

export default class PageTemplate extends React.Component<PageProps<Query>> {
	render() {
		const { contentfulPage } = this.props.data;

		if (!contentfulPage) {
			redirectTo('/404');
			return null;
		}
		return (
			<DefaultLayoutTemplate>
				<Helmet>
					<title>{curatedTitle(contentfulPage.title)}</title>
				</Helmet>
				<SectionContainer withBorderTop>
					{contentfulPage.title && <SectionContainerTitle text={contentfulPage.title} />}
					{contentfulPage.body &&
						renderRichText(contentfulPage.body as any, defaultRichTextOptions)}
				</SectionContainer>
			</DefaultLayoutTemplate>
		);
	}
}
